import { Link } from 'gatsby'
import React from 'react'
import './PrimaryButton.scss'


// eslint-disable-next-line quotes
interface Props {
  className?: string,
  to?: string,
  children: React.ReactNode,
  onClick?: (Event?) => void,
  isLoading?: boolean,
  loadingText?: string,
}

const PrimaryButton: React.FC<Props> = ({
  className = ``,
  to,
  children,
  onClick,
  isLoading = false,
  loadingText = `Loading…`,
}) => {
  const classNames = [`primary-button`, isLoading ? `loading` : ``, className].join(` `)
  const text = isLoading ? loadingText : children
  return to ? (
    <Link className={classNames} to={to}>
      {text}
    </Link>
  ) : (
    <button className={classNames} onClick={onClick}>
      {text}
    </button>
  )
}

export default PrimaryButton

import { Link } from 'gatsby'
import React from 'react'
import './SecondaryButton.scss'

interface Props {
  className?: string,
  to?: string,
  children: React.ReactNode,
  onClick?: (Event?) => void,
  isLoading?: boolean
  loadingText?: React.ReactNode,
}

const SecondaryButton: React.FC<Props> = ({
  className = ``,
  to,
  children,
  onClick,
  isLoading = false,
  loadingText = `Loading…`,
}) => {
  const inside = isLoading ? loadingText : children
  if(to){
    if(to.slice(0, 4) === `http`){
      return (
        <a className={[`secondary-button`, className].join(` `)} href={to}>
          {inside}
        </a>
      )
    }
    return (
      <Link className={[`secondary-button`, className].join(` `)} to={to}>
        {inside}
      </Link>
    )
  }
  return (
    <button className={[`secondary-button`, className].join(` `)} onClick={onClick}>
      {inside}
    </button>
  )
}

export default SecondaryButton

import { navigate } from 'gatsby'
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import ImageInput from '../../components/ImageInput'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Spinner from '../../components/Spinner/Spinner'
import TextInput from '../../components/TextInput'
import UserContext from '../../components/UserContext'
import UserProfile from '../../components/UserProfile'
import Api from '../../lib/Api'
import Cookie from '../../lib/Cookie'
import type { Modify, User } from '../../types/unicornvalley'
import './Settings.scss'

interface LocalUser extends Modify<User, {
  uploadedPicture: File | null,
  uploadedPictureURL: string,
}> {}

const initialUser = {
  bio: ``,
  name: ``,
  password: ``,
  picture: ``,
  uploadedPicture: null,
  uploadedPictureURL: ``,
} as LocalUser

// eslint-disable-next-line sonarjs/cognitive-complexity
const Settings = () => {
  const [user, setUser] = useState(initialUser)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(``)
  const [isSaving, setIsSaving] = useState(false)
  const {
    bio,
    name,
    password,
    picture,
    uploadedPicture,
    uploadedPictureURL,
  } = user

  const { user: me, updateMe } = useContext(UserContext)

  const logout = useCallback(() => {
    Cookie.removeJWT()
    updateMe()
    navigate(`/`)
  }, [updateMe])

  const onChangeMe = useCallback((attribute) => (value) => setUser({
    ...user,
    [attribute]: value,
  }), [setUser, user])

  const onChangePicture = useCallback((files) => {
    if(files.length === 1){
      setUser({
        ...user,
        uploadedPicture: files[0],
        uploadedPictureURL: URL.createObjectURL(files[0]),
      })
    }
    if(files.length === 0){
      setUser({
        ...user,
        uploadedPicture: null,
        uploadedPictureURL: ``,
      })
    }
  }, [user, setUser])

  const patchMe = useCallback(async () => {
    try {
      setIsSaving(true)
  
      const token = Cookie.getJWT()
      await Api.patchUser(token, me.id, {
        name,
        ...(password.length > 0 && { password }),
        bio,
      } as User)
      if(uploadedPicture !== null){
        await Api.uploadUserPicture(token, me.id, uploadedPicture)
      }
      updateMe()
      navigate(`/users/${me.id}`)
    } catch {
      setError(`Could not patch user`)
    } finally {
      setIsSaving(false)
    }
  }, [bio, me.id, name, password, updateMe, uploadedPicture])

  useEffect(() => {
    const token = Cookie.getJWT()

    if(!token){
      return logout()
    }

    (async () => {
      const userResult = await Api.getMe(token)
      setUser({
        ...initialUser,
        bio: userResult.bio,
        name: userResult.name,
        picture: userResult.picture,
      } as LocalUser)
      setIsLoading(false)
    })()
  }, [logout, me])

  return (
    <Layout>
      <SEO
        title="Settings"
      />
      <div id="settings-page">
        <h2>Profile</h2>
        <div className="user-settings">
          {
            isLoading ? (
              <Spinner />
            ) : (
              <>
                <UserProfile
                  user={{
                    ...user,
                    name: name.length > 0 ? name : `Your Name`,
                    picture: uploadedPictureURL.length > 0 ? uploadedPictureURL : picture,
                  }}
                />
                <TextInput
                  label="Name"
                  placeholder="your name"
                  onChangeText={onChangeMe(`name`)}
                  value={name}
                  maxLength={50}
                />
                <TextInput
                  label="Password"
                  placeholder="••••••••••••••••"
                  onChangeText={onChangeMe(`password`)}
                  value={password}
                  type="password"
                  maxLength={70}
                />
                <TextInput
                  label="Bio"
                  placeholder="a short blurb about yourself, no longer than a short tweet"
                  onChangeText={onChangeMe(`bio`)}
                  value={bio}
                  maxLength={100}
                />
                <ImageInput
                  label="Profile Picture"
                  onFilesChange={onChangePicture}
                />

                {error.length > 0
                  ? <p className="error">{error}</p>
                  : ``
                }

                <PrimaryButton
                  onClick={patchMe}
                  isLoading={isSaving}
                  loadingText="SAVING…"
                >
                  SAVE
                </PrimaryButton>
              </>
            )
          }
        </div>

        <h2>Settings</h2>
        <div className="settings">
          <SecondaryButton onClick={logout}>
            <IoIosLogOut />&nbsp;
            LOG OUT
          </SecondaryButton>
        </div>
      </div>
    </Layout>
  )
}

export default Settings